import React from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import Loading from '../Loading';
// utils
import { decimalToText } from '../../utils/decimalToText';
import { setResourceType } from '../../app/features/metasearchSlice';
// Styles
import './NavigationBarModules.css';

const NavigationBarModules = ({
  active,
  type,
  resource,
  metasearcher,
  setActiveBar,
}) => {
  const dispatch = useDispatch();

  const { wordFetched } = useSelector((state) => state.metasearch);
  const { totalData, isLibraryLoadingMoreData } = useSelector(
    (state) => state.library
  );
  const { totalBooks, isBooksLoadingMoreData } = useSelector(
    (state) => state.books
  );
  const { total_multimedia, is_multimedia_loading } = useSelector(
    (state) => state.multimedia
  );
  const { total_news_query, is_news_loading } = useSelector(
    (state) => state.news
  );
  const { totalPatents, isLoadingMoreData } = useSelector(
    (state) => state.patents
  );
  const { elementaryData, medicalData, thesaurusData } = useSelector(
    (state) => state.dictionary
  );
  const { isMedicalInstitution } = useSelector((state) => state.institution);

  const getTotalDictionary = (type) => {
    if (type) {
      return (typeof elementaryData[0] === 'string' ||
        elementaryData.length === 0) &&
        (typeof medicalData[0] === 'string' || medicalData.length === 0)
        ? 0
        : 1;
    } else {
      return typeof elementaryData[0] === 'string' ||
        elementaryData.length === 0
        ? 0
        : 1;
    }
  };

  const getTotalThesaurus = () => {
    return typeof thesaurusData[0] === 'string' || thesaurusData.length === 0
      ? 0
      : 1;
  };

  const getStatus = (resource) => {
    switch (resource) {
      case 'articles':
        return {
          loading: isLibraryLoadingMoreData,
          total: totalData,
        };
      case 'books':
        return {
          loading: isBooksLoadingMoreData,
          total: totalBooks,
        };
      case 'media':
        return {
          loading: is_multimedia_loading,
          total: total_multimedia,
        };
      case 'news':
        return {
          loading: is_news_loading,
          total: total_news_query,
        };
      case 'patents':
        return {
          loading: isLoadingMoreData,
          total: totalPatents,
        };
      case 'dictionary':
        return {
          loading: isLoadingMoreData,
          total: getTotalDictionary(isMedicalInstitution),
        };
      case 'thesaurus':
        return {
          loading: isLoadingMoreData,
          total: getTotalThesaurus(),
        };
      default:
        return null;
    }
  };

  const statusResource =
    type === 'metasearcher' ? getStatus(resource.route) : null;

  const handleClic = (element) => {
    dispatch(setResourceType(element));
    setActiveBar(false);
  };

  return (
    <Link
      to={resource.route}
      className={
        active
          ? 'activeSection'
          : resource.title === 'EBooks'
          ? 'EBookSection module'
          : resource.title === 'IA'
          ? 'EBookSection'
          : ''
      }
      onClick={() => handleClic(resource.route)}
    >
      <img
        className={
          resource.title === 'EBooks'
            ? 'newSource'
            : resource.title === 'IA'
            ? 'newSource'
            : 'sectionIcon'
        }
        src={resource.icon}
        alt='module'
      />
      <p>{resource.title !== 'IA' ? resource.title : 'IA'}</p>
      {metasearcher && wordFetched ? (
        statusResource?.loading && statusResource?.total === 0 ? (
          <Loading color={active ? 'green' : 'black'} />
        ) : (
          <p>{decimalToText(statusResource?.total)}</p>
        )
      ) : null}
      {(resource.title === 'EBooks' || resource.title === 'IA') && (
        <p className='newSection'>New</p>
      )}
    </Link>
  );
};

export default NavigationBarModules;
