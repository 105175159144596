import React, { useRef } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Slices
import {
  setPrompt,
  setPromptFetched,
  setResponse,
  setIsLoading,
} from '../../app/features/iaSlice';
// Icons
import { GrFormNextLink } from 'react-icons/gr';
// Services
import { getIASearch } from '../../services/getIASearch';
// Styles
import './IASearchBar.css';
// Utils
import { centerTo } from '../../utils/centerTo.js';

const IASearchBar = ({ setSearchBarHeight }) => {
  const dispatch = useDispatch();
  const textareaRef = useRef(null);

  const { prompt, response } = useSelector((state) => state.ia);

  const autoResize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Restablece la altura
    textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta la altura
    setSearchBarHeight(textarea.scrollHeight);
  };

  const iaSearchAction = () => {
    dispatch(setIsLoading(true));
    dispatch(setPromptFetched(prompt));
    centerTo('loading');
    getIASearch(prompt)
      .then((res) => {
        if (res.status === 200) {
          const data = Array.isArray(res.data) ? res.data : [res.data];
          dispatch(setResponse(data));
          dispatch(setIsLoading(false));
          centerTo('answer', response.length);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(setResponse([]));
        dispatch(setIsLoading(false));
      });
  };

  return (
    <div className='iaSearch'>
      <textarea
        ref={textareaRef}
        className='iaSearchBar'
        type='text'
        placeholder='Type your search term here'
        onInput={autoResize}
        rows={1}
        onChange={(e) => dispatch(setPrompt(e.target.value))}
      ></textarea>
      <button
        className={
          prompt.length > 0 ? 'iaSearchButton withText' : 'iaSearchButton'
        }
        onClick={iaSearchAction}
      >
        <GrFormNextLink />
      </button>
    </div>
  );
};

export default IASearchBar;
