import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prompt: '',
  promptFetched: '',
  response: [],
  isLoading: false,
};

const iaSlice = createSlice({
  name: 'ia',
  initialState,
  reducers: {
    setPrompt: (state, action) => {
      state.prompt = action.payload;
    },
    setPromptFetched: (state, action) => {
      state.promptFetched = action.payload;
    },
    setResponse: (state, action) => {
      state.response = [...state.response, ...action.payload];
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setPrompt, setPromptFetched, setResponse, setIsLoading } =
  iaSlice.actions;

export default iaSlice.reducer;
