import React from 'react';
import { useNavigate } from 'react-router-dom';
// Images
import iaIcon_1 from '../../Images/ia/iaIcon_1.svg';
import iaIcon_2 from '../../Images/ia/iaIcon_2.svg';
import logoAquinas from '../../Images/logo/logo_OlibBlack.svg';
// Component
import Container from '../../Components/Container';
import IASearchBar from '../../Components/IASearchBar';
// Icons
import { BsInfoCircle } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
// Styles
import './IAInit.css';

const IAInit = ({ setSearchBarHeight }) => {
  const navigate = useNavigate();

  return (
    <main className='iaInit'>
      <Container className='iaInitContainer'>
        <div className='iaContainerButtons'>
          <button
            className='goBackButton'
            onClick={() => navigate('/articles')}
          >
            <IoIosArrowBack />
            <p>Go back</p>
          </button>
          <button>
            <BsInfoCircle />
          </button>
        </div>
        <div className='iaContainerInfo'>
          <img src={logoAquinas} alt='logo Aquinas' />
          <div className='iaContainerInfo__text'>
            <h1>Hello! I'm Aquino, your new virtual study partner.</h1>
            <p>
              Do you have any questions about a particular topic? Feel free to
              ask! To provide you with the best information, I can help you find
              the most relevant articles in our virtual library.{' '}
              <b>
                Please let me know when you would like to switch topics so I can
                offer you more accurate results.
              </b>{' '}
              Remember, while I am a powerful tool, it's always a good idea to
              verify my responses with other sources to ensure you have the most
              comprehensive information. Let's start exploring together!
            </p>
          </div>
          <div className='iaContainerInfo__cards'>
            <div className='iaCardInit'>
              <img src={iaIcon_1} alt='icon book' />
              <p>
                Make searches in libraries from different sources and topics in
                seconds.
              </p>
            </div>
            <div className='iaCardInit'>
              <img src={iaIcon_2} alt='icon searxh' />
              <p>Make quick searches in a variety of specialized articles.</p>
            </div>
          </div>
        </div>
        <div className='iaContainerSearch'>
          <IASearchBar setSearchBarHeight={setSearchBarHeight} />
          <p>
            This assistant can provide automated responses. Always verify key
            information before making decisions.
          </p>
        </div>
      </Container>
    </main>
  );
};

export default IAInit;
