import React, { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Components
import Container from '../../Components/Container';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Services
import { getThesaurusDictionary } from '../../services/getDictionary';
// Slices
import {
  setIsDictionaryDataFetched,
  setThesaurusData,
  setIsThesaurusDataLoading,
  resetDictionaty,
} from '../../app/features/dictionarySlice';
// Styles
import './ThesaurusPage.css';

const ThesaurusPage = () => {
  const { dictionaryQueryFetched, thesaurusData, isThesaurusDataLoading } =
    useSelector((state) => state.dictionary);
  const { queryFetched } = useSelector((state) => state.library);

  useEffect(() => {}, [dictionaryQueryFetched]);

  const { handleScrollToTop } = useScrollToTop();
  const dispatch = useDispatch();

  const changeSearch = (word) => {
    dispatch(resetDictionaty());

    dispatch(setIsThesaurusDataLoading(true));
    getThesaurusDictionary(word)
      .then((response) => {
        if (typeof response.data[0] !== 'string') {
          let dataThesaurus = [];
          response.data.map((element) => {
            dataThesaurus.push({
              meta: element.meta,
              shortdef: element.shortdef,
            });
            return null;
          });
          dispatch(setThesaurusData(dataThesaurus));
        } else {
          dispatch(setThesaurusData(response?.data));
        }
        dispatch(setIsThesaurusDataLoading(false));
        dispatch(setIsDictionaryDataFetched(true));
      })
      .catch((error) => {
        console.error('Request error:', error);
        dispatch(setIsThesaurusDataLoading(false));
      });
  };

  const definitionList = (list) => {
    if (list.length > 0) {
      return (
        <ul>
          {list.map((def, i) => (
            <li className='definition' key={i}>
              {def?.replace(': as', '')}
            </li>
          ))}
        </ul>
      );
    } else {
      return null;
    }
  };

  // // Definition data from word
  // const getDefinitionsList = () => {
  //   let definitions = [];
  //   const getDefinition = (data) => {
  //     data.forEach((def) => {
  //       def.shortdef.forEach((shortdef) => {
  //         definitions.push(shortdef);
  //       });
  //     });
  //   };

  //   typeof thesaurusData[0] !== 'string' && getDefinition(thesaurusData);

  //   if (definitions.length > 0) {
  //     return (
  //       <>
  //         <h3 className='titleSectionResult' id='definition'>
  //           Definition
  //         </h3>
  //         <ul>
  //           {Array.from(new Set(definitions)).map((shortdef, i) => (
  //             <li className='definition' key={i}>
  //               {shortdef.replace(': as', '')}
  //             </li>
  //           ))}
  //         </ul>
  //       </>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Stems data from word
  const getStems = () => {
    let stems = [];

    if (thesaurusData?.length > 0 && thesaurusData[0].meta) {
      stems = [...stems, ...thesaurusData[0].meta.stems];
    }

    if (stems?.length > 0) {
      stems = [...new Set(stems)];
      return (
        <>
          <h3 className='titleSectionResult' id='stem'>
            Stem
          </h3>
          {definitionList(stems)}
        </>
      );
    } else {
      return null;
    }
  };

  // Stems data from word
  const getSyns = () => {
    let syns = [];

    if (thesaurusData?.length > 0 && thesaurusData[0].meta) {
      syns = [...syns, ...thesaurusData[0].meta.syns[0]];
    }

    if (syns?.length > 0) {
      syns = [...new Set(syns)];
      return (
        <>
          <h3 className='titleSectionResult' id='syns'>
            Synonyms
          </h3>
          {definitionList(syns)}
        </>
      );
    } else {
      return null;
    }
  };

  // Related Terms data from word
  const getRelatedTerms = () => {
    let relatedTerms = [];

    // Verificar que la estructura de datos tenga los niveles necesarios
    if (
      thesaurusData?.length > 0 &&
      thesaurusData[0].def?.length > 0 &&
      thesaurusData[0].def[0].sseq?.length > 0 &&
      thesaurusData[0].def[0].sseq[0]?.length > 0 &&
      thesaurusData[0].def[0].sseq[0][0]?.length > 1 &&
      thesaurusData[0].def[0].sseq[0][0][1]?.rel_list?.length > 0
    ) {
      const dataRelatedTerms =
        thesaurusData[0].def[0].sseq[0][0][1].rel_list[0];

      dataRelatedTerms.forEach((element) => {
        relatedTerms.push(element.wd);
      });
    }

    if (relatedTerms.length > 0) {
      relatedTerms = [...new Set(relatedTerms)];
      return (
        <>
          <h3 className='titleSectionResult' id='relatedTerms'>
            Related terms
          </h3>
          {definitionList(relatedTerms)}
        </>
      );
    } else {
      return null;
    }
  };

  // Entries Near data from word
  const getEntriesNearList = () => {
    let entriesNearList = [];
    const getEntriesNear = (data) => {
      data?.map((entrieNear) => {
        entriesNearList.push(entrieNear);
        return null;
      });
    };

    typeof thesaurusData[0] === 'string' && getEntriesNear(thesaurusData);

    if (entriesNearList.length > 0) {
      return (
        <>
          <h3 className='titleSectionResult' id='entriesNear'>
            Entries near
          </h3>
          <div className='entriesNearContainer'>
            {entriesNearList.map((entrieNear, i) => {
              return (
                <button
                  className='entrieNearButton'
                  key={i}
                  onClick={(e) => {
                    handleScrollToTop(280);
                    e.preventDefault();
                    changeSearch(entrieNear);
                  }}
                >
                  <p>{entrieNear}</p>
                </button>
              );
            })}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {thesaurusData?.length > 0 ? (
        <main className='thesaurus-main'>
          <Container className='thesaurus'>
            <section className='thesaurusNavigation'>
              <div className='thesaurusNavigationContainer'>
                <p className='thesaurusNavigation__title'>
                  <b>Content in this word</b>
                </p>
                <div className='thesaurusNavigation__categories'>
                  {/* {thesaurusData?.length > 0 && thesaurusData[0].meta ? (
                    <a href='#definition'>
                      <p className='contentCategory'>Definition</p>
                    </a>
                  ) : null} */}

                  {thesaurusData?.length > 0 &&
                  thesaurusData[0].meta.syns[0].length > 0 ? (
                    <a href='#syns'>
                      <p className='cosntentCategory'>Synonyms</p>
                    </a>
                  ) : null}

                  {thesaurusData?.length > 0 &&
                  thesaurusData[0].def?.length > 0 &&
                  thesaurusData[0].def[0].sseq?.length > 0 &&
                  thesaurusData[0].def[0].sseq[0]?.length > 0 &&
                  thesaurusData[0].def[0].sseq[0][0]?.length > 1 &&
                  thesaurusData[0].def[0].sseq[0][0][1]?.rel_list?.length >
                    0 ? (
                    <a href='#relatedTerms'>
                      <p className='contentCategory'>Related terms</p>
                    </a>
                  ) : null}

                  {thesaurusData?.length > 0 &&
                  thesaurusData[0].meta.stems.length > 0 ? (
                    <a href='#stem'>
                      <p className='cosntentCategory'>Stem</p>
                    </a>
                  ) : null}

                  {thesaurusData?.length > 0 &&
                  typeof thesaurusData[0] === 'string' ? (
                    <a href='#entriesNear'>
                      <p className='contentCategory'>Entries near</p>
                    </a>
                  ) : null}
                </div>
              </div>
            </section>
            <section className='thesaurusResults'>
              <div className='thesaurusResultsContainer'>
                <h2 className='searchWord'>
                  {thesaurusData?.length > 0 && thesaurusData[0].meta
                    ? thesaurusData[0].meta.id.split(':')[0]
                    : null}
                </h2>
                <p className='wordType'>
                  {thesaurusData?.length > 0 && thesaurusData[0].meta
                    ? thesaurusData[0].fl
                    : null}
                </p>

                {/* {getDefinitionsList()} */}

                {getSyns()}

                {getRelatedTerms()}

                {getStems()}

                {getEntriesNearList()}
              </div>
            </section>
          </Container>
        </main>
      ) : (
        <div className='noDataContainerThesaurus'>
          {isThesaurusDataLoading === true ? (
            <span className='loaderThesaurus'></span>
          ) : queryFetched ? (
            <p className='noDataFound'>No data found</p>
          ) : (
            <p>Please enter a search term</p>
          )}
        </div>
      )}
    </>
  );
};

export default ThesaurusPage;
